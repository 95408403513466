<template>
  <v-card>
    <v-card-title>
      Create Marketing Brochure
    </v-card-title>
    <v-card-text>
      <p>Use this feature to create marketing brochures for printing or emailing</p>
      <p>This could be used to create categories of brochures as required.</p>
      <ul>
        <li>Bestsellers</li>
        <li>Categories (eg Giftware)</li>
        <li>Regional (Skye-related)</li>
        <li>Branded brochures for use in-house by customers or self-publishers</li>
      </ul>
    </v-card-text>  </v-card>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  async created () {
  }
}
</script>
